.mauto {
  margin: 0 auto;
}

.screen-loader {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.8);
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  z-index: 9;
}

.css-1bh7w1f-MuiPaper-root-MuiDrawer-paper {
  background-color: #000 !important;
}

.pre-pos {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: white;
  padding: 10px 5px;
}

a {
  text-decoration: none !important;
}

.inner_label_box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.user_performance {
  border-radius: 20px;
  padding: 15px;
  border: 1px solid #101828;
  height: 100%;
  justify-content: space-between !important;
}

.card-item-text {
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.text-dark {
  color: #101828 !important;
}

.align-items-center {
  align-items: center !important;
}

.d-flex {
  display: flex !important;
}

.card-item-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
}

.card-item-progress .percent {
  position: relative;
}

.card-item-progress svg {
  position: relative;
  width: 140px;
  height: 140px;
  aspect-ratio: 1/1;
  transform: rotate(-90deg);
}

.card-item-progress svg circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: #f0f2f6;
  stroke-width: 13;
  stroke-linecap: round;
}

.card-item-progress .number {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  text-align: center;
  font-size: 16px;
  transform: translate(-50%, -50%);
}

.m-0 {
  margin: 0 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.outline-card {
  border-radius: 20px;
  padding: 15px;
  border: 1px solid #101828;
  height: 100%;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.border-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0;
  color: #101828;
  padding-block: 14px;
  border-bottom: 1px solid #d0d5dd;
}

.border-list li .tags-wrap {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 0;
  gap: 10px;
}

.border-list li .tags-wrap span {
  border-radius: 50px;
  background-color: #d7e3ff;
  color: #3065fa;
  padding: 5px 15px;
  font-size: 14px;
}

.outline-card h5 {
  font-size: 18px;
}

.select-ques-modal .css-djyf7k {
  max-width: 800px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.select-ques-modal .select-ques-content {
  display: flex;
  align-items: start;
  gap: 15px;
}

.select-ques-modal .select-ques-content p {
  margin-top: 9px;
}

.inner_details {
  display: flex;
  align-items: center;
  justify-content: start;
}

.select-ques-content p span {
  font-weight: bold;
}

.select-ques-content .MuiStack-root.css-10ba5sk-MuiStack-root {
  width: 100% !important;
}

/* app content */
.multi-image-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.multi-image-container img {
  height: 100%;
}

/* card headings */
.thumb-box {
  color: #0067f4;
  transition: all 0.3s ease;
}

.thumb-box:hover {
  color: #005bdb;
}

.thumb-box .css-141zebx-MuiStack-root {
  transition: all 0.3s ease;
}

.thumb-box:hover .css-141zebx-MuiStack-root {
  color: #fff;
  background-color: #0067f4;
}

/* popup */
.pre-pos {
  display: none;
}

/* .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-16e0f6q-MuiSvgIcon-root {
  position: absolute;
  top: -7px;
  right: -7px;
  background: white;
  border-radius: 50%;
} */

h4,
.h4 {
  font-size: clamp(1.125rem, 1.0654rem + 0.2981vw, 1.25rem);
}

h5,
.h5 {
  font-size: clamp(1rem, 0.9404rem + 0.2981vw, 1.125rem);
}

.text-dark {
  color: #101828 !important;
}

.container-blk {
  padding: 0 30px;
}

.fw-5 {
  font-weight: 500;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.button-grp {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px 30px;
  flex-wrap: wrap;
}

.btn {
  padding: 10px 16px;
  border-radius: 8px;
  text-align: center;
  background-color: #f0f2f6;
  color: #667085;
}

.theme-btn {
  background-color: #3065fa;
  color: #fff !important;
}

.theme-btn:active {
  color: #fff !important;
  border-color: #3065fa !important;
  background-color: #2553d4 !important;
}

.theme-btn:hover {
  background-color: #2553d4;
}

.theme-btn.disabled {
  border-color: #f0f2f6;
  color: #667085 !important;
  background-color: #f0f2f6;
}

.theme-btn i {
  font-size: 14px;
}

.test-btn {
  font-weight: 600;
  padding: 10px 40px;
}

.success-btn,
.success-btn:hover,
.success-btn:active {
  color: #027a48 !important;
  background-color: #d1fadf !important;
  border: unset !important;
}

.warning-btn,
.warning-btn:hover,
.warning-btn:active {
  color: #f79009 !important;
  background-color: #f4fad1 !important;
  border: unset !important;
}

.danger-btn,
.danger-btn:hover,
.danger-btn:active {
  color: #d92d20 !important;
  background-color: #fad1d1 !important;
  border: unset !important;
}

.cancel-btn,
.cancel-btn:hover,
.cancel-btn:active {
  color: #fff;
  background-color: #f04438;
}

li.MuiBreadcrumbs-li {
  color: #000 !important;
}

li.MuiBreadcrumbs-li {
  color: #000 !important;
}

.css-1a0m6tf-MuiFormLabel-root-MuiInputLabel-root {
  color: rgb(99, 115, 129);
}

.css-qa6xv2-MuiFormLabel-root-MuiInputLabel-root {
  color: rgb(99, 115, 129);
}

.css-3mf706,
li.MuiBreadcrumbs-separator.css-1wuw8dw-MuiBreadcrumbs-separator {
  color: #000;
}

p.css-1wu8d21,
p.MuiTypography-root.MuiTypography-body2.MuiTypography-noWrap.css-f81w41-MuiTypography-root {
  color: #000;
}

.css-1t53ozj {
  background-color: #000000bd;
}

label.css-1tmi7gn,
.css-8rrhzn-MuiFormLabel-root-MuiInputLabel-root {
  color: #212b36;
}

.btn_icon_delete {
  background: #961111;
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  border-radius: 50%;
}

.css-84rwpv-MuiFormLabel-root-MuiInputLabel-root {
  color: #000;
  transform: translate(14px, 10px) scale(1) !important;
}

input.form-control.dob-field.small-text.date-field {
  color: #475467;
  cursor: pointer;
  font-weight: 500;
  max-width: 165px;
  padding: 10px 10px 10px 35px;
  text-align: center;
  width: 100%;
}

.date_selector svg {
  bottom: 0;
  left: 20px;
  margin: auto;
  position: absolute;
  top: 0;
}

.css-8h9gbs-MuiTypography-root-MuiDialogContentText-root {
  color: #000;
}

.button_mediaType {
  width: 100%;
  background: transparent;
  border: 0;
  cursor: pointer;
}

/* New Css */

.simplebar-content .css-1j2w5dv {
  width: 90px;
}

.css-konvg8-MuiButtonBase-root-MuiListItemButton-root.active,
.css-1fvkfdw.active,
.css-1fvkfdw:hover,
a.MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-root.css-konvg8-MuiButtonBase-root-MuiListItemButton-root.active,
.css-konvg8-MuiButtonBase-root-MuiListItemButton-root:hover {
  background: #ffffff26 !important;
  color: #ffffff !important;
}

.css-1fvkfdw,
.css-konvg8-MuiButtonBase-root-MuiListItemButton-root {
  margin: 0 0 12px;
}

.date-wrapper {
  width: 100%;
  max-width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 15px;
}

.form-control.date-field,
.MuiSelect-select {
  border: 1px solid #e0e0e0;
  padding: 12px !important;
  border-radius: 8px;
  background-color: #fff !important;
}

.MuiInputBase-root:focus {
  border: none !important;
}

.MuiSelect-select {
  padding: 12px 26px 12px 12px !important;
}

.date_selector {
  position: relative;
}

.dateClear {
  /* position: absolute; */
  top: -9px;
  right: 0;
  background: red;
  border: none;
  color: #fff;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 18px;
  height: 18px;
  font-size: 12px;
  cursor: pointer;
}

.css-i44wyl,
.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
  max-width: 160px;
}

label.css-1kd4w1 {
  color: #707070 !important;
}

.login-form .css-i44wyl {
  width: 100%;
  max-width: 100%;
  color: #000 !important;
}

.MuiContainer-root.MuiContainer-maxWidthSm.css-cuefkz-MuiContainer-root
  .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
  max-width: 100%;
}

.MuiPaper-root video,
.MuiPaper-root .react-audio-player {
  width: 100% !important;
  height: 164px !important;
}

.MuiCardContent-root .css-177rv55 {
  height: unset;
  color: #252525;
  font-size: 16px !important;
  font-weight: 600;
  line-height: 30px;
  text-transform: capitalize;
}

.MuiTablePagination-root {
  margin: 20px 0 0;
  width: 100%;
}

.MuiTablePagination-root .MuiToolbar-root.MuiToolbar-gutters {
  width: 100%;
  gap: 10px;
  justify-content: center !important;
  align-items: center;
}

.MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault.css-quc6hk-MuiAvatar-root {
  background-color: #fa6100;
  font-size: 12px;
}

.css-10v8o86::before {
  background-color: transparent;
}

.css-c7pmvz,
.css-gd6vol-MuiButtonBase-root-MuiButton-root:hover,
.MuiButton-root:hover,
.css-9mwnm-MuiAvatar-root {
  background-color: #fa6100;
}

.MuiGrid-container .button_mediaType:hover svg {
  color: #fa6100;
}

.css-4jra3r-MuiButtonBase-root-MuiIconButton-root:before {
  content: unset;
}

.css-1rdnei9,
.MuiStack-root.css-1g1s94c-MuiStack-root {
  gap: 15px;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl:focus-visible {
  outline: none;
}

.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 10px 12px !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiCard-root.usre-card-section.css-1x851mw-MuiPaper-root-MuiCard-root
  h4 {
  padding: 0 0 0 30px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiCard-root.usre-card-section.css-1x851mw-MuiPaper-root-MuiCard-root
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-16e0f6q-MuiSvgIcon-root {
  position: absolute;
  top: -5px;
  right: 20px;
}

.MuiBox-root.css-e67k31 video {
  height: 310px !important;
}

.css-s2gsgl,
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiCard-root.css-14irmdm-MuiPaper-root-MuiCard-root,
.MuiCardContent-root {
  height: 100%;
}

.css-e5r7u1 {
  color: #fa6100;
  width: 20px;
  height: 20px;
}

label.css-1kd4w1 {
  transform: translate(14px, 10px) scale(1) !important;
}
.simplebar-placeholder {
  display: none;
}
